/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";

import styledlinkStyle from "./assets/StyledLink.module.scss";

const StyledLink = ({to, href, target, external, className, children, ...other}) => {
  let Tag = null;
  if (to) {
    Tag = Link;
  } else if (href) {
    Tag = "a";
  } else {
    console.error("Tag for StyledLink not specified");
  }

  const linkAnimation = (e) => {
    const link = e.target;
    if (!link.classList.contains(styledlinkStyle["animated"])) {
      link.classList.add(styledlinkStyle["animated"]);
      const duration = Number(window.getComputedStyle(link, ":before").getPropertyValue("transition-duration").split(",")[0].slice(0, -1)) * 1000;
      setTimeout(function () {
        link.classList.remove(styledlinkStyle["animated"]);
      }, duration * 2);
    }
  };

  const ExternalIcon = (
    <svg className={styledlinkStyle.external_icon} viewBox="0 0 24 24">
      <path d="M17 13v6c0 0.276-0.111 0.525-0.293 0.707s-0.431 0.293-0.707 0.293h-11c-0.276 0-0.525-0.111-0.707-0.293s-0.293-0.431-0.293-0.707v-11c0-0.276 0.111-0.525 0.293-0.707s0.431-0.293 0.707-0.293h6c0.552 0 1-0.448 1-1s-0.448-1-1-1h-6c-0.828 0-1.58 0.337-2.121 0.879s-0.879 1.293-0.879 2.121v11c0 0.828 0.337 1.58 0.879 2.121s1.293 0.879 2.121 0.879h11c0.828 0 1.58-0.337 2.121-0.879s0.879-1.293 0.879-2.121v-6c0-0.552-0.448-1-1-1s-1 0.448-1 1zM10.707 14.707l9.293-9.293v3.586c0 0.552 0.448 1 1 1s1-0.448 1-1v-6c0-0.136-0.027-0.265-0.076-0.383s-0.121-0.228-0.216-0.323c-0.001-0.001-0.001-0.001-0.002-0.002-0.092-0.092-0.202-0.166-0.323-0.216-0.118-0.049-0.247-0.076-0.383-0.076h-6c-0.552 0-1 0.448-1 1s0.448 1 1 1h3.586l-9.293 9.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0z"/>
    </svg>
  );

  return (
    <Tag
      to={to || null}
      href={href || null}
      target={target || null}
      className={`${className || ""} ${styledlinkStyle["link"]}`}
      {...other}
    >
      <span 
        className={styledlinkStyle["link_lines"]} 
        onMouseEnter={linkAnimation}
      >
        {children}
        {external && (ExternalIcon)}
      </span>
    </Tag>
  );
};

StyledLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  external: PropTypes.bool,
  className: PropTypes.object,
  children: PropTypes.node.isRequired
}

export default StyledLink;