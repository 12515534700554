import React, {Fragment} from "react";
import PropTypes from "prop-types";

import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";

const Layout = ({children, title}) => {
  return (
    <Fragment>
      <Head title={title} />
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default Layout;