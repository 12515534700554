import React, {useEffect} from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function Head({title, lang}) {
  useEffect(() => {
    document.body.className = document.body.className.replace(/\bpreloader\b/, '');
  }, []);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author,
            assets
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{lang}}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
    >
      <meta http-equiv="Content-Type" content="text/html" />
      <title>{title}</title>
      <meta name="description" content={site.siteMetadata.description} />
      <meta property="og:title" content={site.siteMetadata.title} />
      <meta property="og:description" content={site.siteMetadata.description} />
      <meta property="og:image" content={`${site.siteMetadata.assets}/og-image.jpg`} />
      <meta property="og:image:height" content="1201" />
      <meta property="og:image:width" content="2294" />
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <link rel="apple-touch-icon" sizes="180x180" href={`${site.siteMetadata.assets}/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`${site.siteMetadata.assets}/favicon-32x32.png`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`${site.siteMetadata.assets}/favicon-16x16.png`} />
      <link rel="manifest" href={`${site.siteMetadata.assets}/site.webmanifest`} />
      <link rel="mask-icon" href={`${site.siteMetadata.assets}/safari-pinned-tab.svg`} color="#000000" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  )
};

Head.defaultProps = {
  lang: `cs`
};

export default Head;
